import { IM, ModalController, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useRef, useState } from 'react'
import type Toast from 'react-native-toast-notifications'

import type { Document, EmployeeTimeType } from '../apis/types/apiResponseTypes'
import LoadingSpinnerModal from '../components/Infominds/LoadingSpinnerModal'
import Modal from '../components/Infominds/Modal'
import { ScreenHeader } from '../components/ScreenHeader'
import useNewTimeHandler from '../hooks/useNewTimeHandler'
import useUserSettings from '../hooks/useUserSettings'
import DateUtils from '../utils/DateUtils'
import EmployeeUtils from '../utils/EmployeeUtils'
import TimeSelectionView from '../views/NewTime/TimeSelectionView'

export type CreateTimeData = {
  date?: Date
  activity?: EmployeeTimeType
  document?: Document
}

type CreateTimeModalProps = {
  controller: ModalController<CreateTimeData>
  onTimeCreated: () => void
}

export default function CreateTimeModal({ controller, onTimeCreated }: CreateTimeModalProps) {
  const { language } = useLanguage()
  const { userSettings } = useUserSettings()
  const toastRef = useRef<Toast | undefined>(undefined)
  const handler = useNewTimeHandler({ presetDate: controller.data?.date })

  const [loading, setLoading] = useState(false)

  const allowCreate = !handler.missingDataToCreate.length

  useEffect(() => {
    if (!controller.isShown) return
    handler.setSelectedActivity(controller.data?.activity)
    handler.setSelectedDocument(controller.data?.document)
    handler.setNote('')
  }, [controller.isShown])

  function createTime() {
    if (!handler.selectedActivity) return

    setLoading(true)

    EmployeeUtils.createTime(
      userSettings?.employee?.id ?? '',
      handler.date ?? new Date(),
      handler.from,
      handler.until,
      handler.selectedActivity,
      handler.selectedDocument,
      handler.employeeSelector.items,
      handler.note
    )
      .then(() => {
        onTimeCreated()
        controller.close()
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Modal
      statusBarTranslucent
      isVisible={controller.isShown}
      onClose={controller.close}
      toastRef={toastRef}
      screenHeader={
        <ScreenHeader
          goBack={controller.close}
          closeIcon={['fal', 'check']}
          close={allowCreate ? createTime : undefined}
          title={DateUtils.formatDate(controller.data?.date ?? new Date(), 'dd MMMM yyyy', language)}
        />
      }>
      <IM.ScrollView>
        <TimeSelectionView handler={handler} creationMode={true} toastRef={toastRef} onModalClose={controller.close} />
      </IM.ScrollView>
      <LoadingSpinnerModal isVisible={loading} />
    </Modal>
  )
}
