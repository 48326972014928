import { IM } from '@infominds/react-native-components'
import React, { createRef, useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'
import { useRecoilValue } from 'recoil'

import type { Document, Folder } from '../../apis/types/apiResponseTypes'
import InfoboxHeader from '../../components/InfoboxHeader'
import InfoboxTabletHeader from '../../components/InfoboxTabletHeader'
import Screen from '../../components/Infominds/Screen'
import { ScreenHeader } from '../../components/ScreenHeader'
import useLayout from '../../hooks/useLayout'
import { DocumentSelectionModalData } from '../../modals/DocumentSelectionModal'
import { InfoBoxStackScreenProps } from '../../navigation/type'
import { ModalScreenProps } from '../../types'
import { assetsToSyncAtom } from '../../utils/stateManager'
import InfoBoxDocumentsView from '../../views/InfoBox/InfoBoxDocumentsView'
import InfoBoxFolderView from '../../views/InfoBox/InfoBoxFolderView'
import InfoBoxMediaView, { InfoboxMediaViewImperativeMethods } from '../../views/InfoBox/InfoBoxMediaView'

export default function InfoBoxScreen({
  controller,
  route,
  onModalDocumentSelected,
}: Omit<InfoBoxStackScreenProps<'Infobox'>, 'navigation'> & ModalScreenProps<void> & DocumentSelectionModalData) {
  const { isSmallDevice } = useLayout()

  const [selectedFolder, setSelectedFolder] = useState<Folder | undefined>()
  const [selectedDocument, setSelectedDocument] = useState<Document | undefined>()

  const mediaRef = createRef<InfoboxMediaViewImperativeMethods>()
  const assetToSync = useRecoilValue(assetsToSyncAtom)

  useEffect(() => {
    if (route.params?.document) {
      setSelectedDocument(route.params.document)
      setSelectedFolder(undefined)
    }
  }, [route.params?.document])

  useEffect(() => {
    setSelectedFolder(undefined)
    setSelectedDocument(undefined)
  }, [isSmallDevice])

  function handleDocumentPress(document: Document) {
    if (isSmallDevice) return

    if (assetToSync > 0) {
      mediaRef.current?.mediaAlert()
    } else {
      setSelectedDocument(document)
      setSelectedFolder(undefined)
    }
  }

  function handleFolderPress(folder: Folder) {
    if (assetToSync > 0) return mediaRef.current?.mediaAlert()

    setSelectedFolder(folder)
  }

  function handleMediaUpload() {
    mediaRef.current?.handleUpload()
    setSelectedFolder(undefined)
  }

  const isModal = controller !== undefined

  return (
    <Screen
      hideBackgroundImage={!isSmallDevice && isModal}
      screenHeader={
        <ScreenHeader
          close={isModal ? () => controller?.close() : undefined}
          barContent={
            <InfoboxHeader
              documentName={selectedDocument?.code}
              mediaHeader={<InfoboxTabletHeader onMediaUpload={handleMediaUpload} />}
              forceSmallDevice={isModal}
            />
          }
        />
      }>
      <IM.MasterDetail forceSmallDevice={isModal}>
        <IM.MasterDetail.Master>
          <InfoBoxDocumentsView document={selectedDocument} onDocumentPress={handleDocumentPress} onDocumentSelected={onModalDocumentSelected} />
        </IM.MasterDetail.Master>

        <IM.MasterDetail.Detail>
          <IM.MasterDetail flexRatio={0.5}>
            <IM.MasterDetail.Master>
              <InfoBoxFolderView
                document={selectedDocument}
                selectedFolder={selectedFolder}
                onFolderPress={handleFolderPress}
                onResetSelection={() => {
                  setSelectedFolder(undefined)
                  setSelectedDocument(undefined)
                }}
              />
            </IM.MasterDetail.Master>
            <IM.MasterDetail.Detail>
              {selectedDocument && (
                <IM.View spacing="bottom" style={styles.mediaContainer}>
                  <InfoBoxMediaView
                    ref={mediaRef}
                    id={selectedDocument.id}
                    infoboxType={selectedDocument.documentType}
                    folderNumber={selectedFolder?.number}
                    folderRight={selectedFolder?.right}
                  />
                </IM.View>
              )}
            </IM.MasterDetail.Detail>
          </IM.MasterDetail>
        </IM.MasterDetail.Detail>
      </IM.MasterDetail>
    </Screen>
  )
}

const styles = StyleSheet.create({
  mediaContainer: {
    flex: 1,
  },
})
