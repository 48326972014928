/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { IM, IMLayout, openTeamViewer, useLanguage, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import { useNavigation } from '@react-navigation/native'
import React, { createRef, useCallback, useEffect, useState } from 'react'
import { Image, Platform, Pressable, ScrollView, StyleSheet } from 'react-native'
import DeviceInfo from 'react-native-device-info'
import { useRecoilState } from 'recoil'

import MicrosoftLogo from '../../assets/img/MicrosoftLogo.svg'
import UserCard from '../../cards/UserCard'
import CompanySelector, { CompanySelectorRef } from '../../components/CompanySelector'
import TextWithIcon from '../../components/Infominds/TextWithIcon'
import LanguageSelector, { LanguageSelectorRef } from '../../components/LanguageSelector'
import SettingsRow from '../../components/SettingsRow'
import SettingsRowContainer from '../../components/SettingsRowContainer'
import { CONSTANTS } from '../../constants/Constants'
import useIsEmployeeTimeEnabled from '../../hooks/useIsEmployeeTimeEnabled'
import { saveMediaOnDeviceAtom } from '../../utils/stateManager'

export default function SettingsView() {
  const navigation = useNavigation()
  const { loginType, license, name, url, sessionKey, backendVersion } = useAuthentication()
  const { i18n } = useLanguage()
  const { theme, colorScheme, updateColorScheme } = useTheme()
  const isEmployeeTimeEnabled = useIsEmployeeTimeEnabled()

  const [companyOpen, setCompanyOpen] = useState(false)
  const [languageOpen, setLanguageOpen] = useState(false)

  const [saveMedia, setSaveMedia] = useRecoilState(saveMediaOnDeviceAtom(sessionKey))

  const companyRef = createRef<CompanySelectorRef>()
  const languageRef = createRef<LanguageSelectorRef>()

  useEffect(() => {
    languageOpen && companyRef.current?.close()
  }, [languageOpen])

  useEffect(() => {
    companyOpen && languageRef.current?.close()
  }, [companyOpen])

  const toggleSwitch = useCallback(() => {
    if (colorScheme === 'light') {
      updateColorScheme('dark')
    } else {
      updateColorScheme('light')
    }
  }, [colorScheme])

  const resetApp = () => {
    navigation.reset({
      routes: [
        {
          name: 'BottomTab',
          state: {
            routes: [{ name: 'SettingsStack', state: { routes: [{ name: 'Setting' }] } }],
          },
        },
      ],
    })

    Platform.OS === 'web' && window.location.reload() // Force backend token revalidation
  }

  return (
    <ScrollView indicatorStyle={colorScheme === 'light' ? 'black' : 'white'} contentContainerStyle={styles.container}>
      {/* eslint-disable-next-line react-native/no-inline-styles */}
      <IM.View style={{ marginBottom: 12 }}>
        <UserCard />
      </IM.View>

      <SettingsRowContainer title={i18n.t('APP')} spacing="vertical">
        <SettingsRow title={i18n.t('SETTINGS_DARKMODE')} isSwitch>
          <IM.Switch onValueChange={toggleSwitch} value={colorScheme === 'dark'} />
        </SettingsRow>

        {/* eslint-disable-next-line react-native/no-inline-styles */}
        <SettingsRow title={i18n.t('SETTINGS_LANGUAGE')} style={[styles.dropdownRow, { zIndex: languageOpen ? 2 : 1 }]}>
          <LanguageSelector
            ref={languageRef}
            onOpen={() => {
              setCompanyOpen(false)
              setLanguageOpen(true)
            }}
            onReLogIn={resetApp}
          />
        </SettingsRow>
        {/* eslint-disable-next-line react-native/no-inline-styles */}
        <SettingsRow title={i18n.t('SETTINGS_MANDANT')} style={[styles.dropdownRow, { zIndex: languageOpen ? 1 : 2 }]}>
          <CompanySelector
            ref={companyRef}
            onOpen={() => {
              setCompanyOpen(true)
              setLanguageOpen(false)
            }}
            onReLogIn={resetApp}
          />
        </SettingsRow>
        {!isEmployeeTimeEnabled && (
          <SettingsRow title={i18n.t('SETTINGS_SAVE_ON_GALLERY')} isSwitch>
            <IM.Switch onValueChange={setSaveMedia} value={saveMedia} />
          </SettingsRow>
        )}
        <SettingsRow title={i18n.t('SETTINGS_SUPPORT')} style={[styles.dropdownRow]}>
          <Pressable
            onPress={() => {
              openTeamViewer().catch(err => console.error('Open teamViewer error', err))
            }}>
            <TextWithIcon secondary alignIcon="right" icon={['fal', 'chevron-right']} color={theme.textDetail}>
              {i18n.t('OPEN')}
            </TextWithIcon>
          </Pressable>
        </SettingsRow>
      </SettingsRowContainer>

      <SettingsRowContainer title={i18n.t('INFO')} spacing="vertical">
        <SettingsRow title={i18n.t('SETTINGS_VERSION')} compact>
          <IM.Text secondary selectable style={styles.text}>
            {Platform.OS === 'web' ? CONSTANTS.appName : DeviceInfo.getApplicationName()} v
            {Platform.OS === 'web' ? process.env.VERSION : DeviceInfo.getVersion()}
            {(Platform.OS === 'android' || Platform.OS === 'ios') && ` (${DeviceInfo.getBuildNumber()})`}
          </IM.Text>
        </SettingsRow>
        <SettingsRow title={i18n.t('SETTINGS_API_VERSION')} compact>
          <IM.Text secondary selectable style={styles.text}>
            v{backendVersion}
          </IM.Text>
        </SettingsRow>
        <SettingsRow title={i18n.t('SETTINGS_LICENSE')} compact>
          <IM.Text secondary selectable style={styles.text}>
            {license}
          </IM.Text>
        </SettingsRow>
        <SettingsRow title={i18n.t('USERNAME')} compact>
          <IM.View style={[IMLayout.flex.row, styles.nameContainer]}>
            <IM.Text secondary selectable style={styles.text}>
              {name}RT
            </IM.Text>
            {loginType === 'AAD' ? (
              <IM.View style={styles.microsoftLogo}>
                <MicrosoftLogo />
              </IM.View>
            ) : (
              <>
                {colorScheme === 'light' ? (
                  <Image source={require('../../assets/img/InfomindsLogoDark.png')} style={styles.infomindsLogo} />
                ) : (
                  <Image source={require('../../assets/img/InfomindsLogoLight.png')} style={styles.infomindsLogo} />
                )}
              </>
            )}
          </IM.View>
        </SettingsRow>
        <SettingsRow title={i18n.t('SETTINGS_SERVER')} compact>
          <IM.Text secondary selectable style={styles.text}>
            {url}
          </IM.Text>
        </SettingsRow>
      </SettingsRowContainer>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: { paddingHorizontal: IMLayout.horizontalMargin },
  nameContainer: { flex: 1, alignItems: 'center', justifyContent: 'flex-end' },
  dropdownRow: { zIndex: 1 },
  text: { flex: 1, textAlign: 'right', justifyContent: 'flex-end' },
  microsoftLogo: { width: 12, aspectRatio: 1, marginLeft: 4 },
  infomindsLogo: { width: 16, aspectRatio: 1, marginLeft: 4 },
})
