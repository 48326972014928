import { IM, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Platform, SectionList, StyleSheet } from 'react-native'
import { useRecoilState, useRecoilValue } from 'recoil'

import api from '../../apis/apiCalls'
import { GetDocumentsRequest } from '../../apis/types/apiRequestTypes'
import type { Document } from '../../apis/types/apiResponseTypes'
import DocumentCard from '../../cards/DocumentCard'
import DocumentsSearchBox from '../../components/DocumentsSearchBox'
import IMRefreshControl from '../../components/IMRefreshControl'
import SkeletonCard from '../../components/Infominds/Skeleton/SkeletonCard'
import NoEntry from '../../components/NoEntry'
import { CONSTANTS } from '../../constants/Constants'
import useLayout from '../../hooks/useLayout'
import useListLoader from '../../hooks/useListLoader'
import { InfoBoxStackParamList } from '../../navigation/type'
import { infoboxFilterPreferenceAtom, lastUsedInfoboxAtom } from '../../utils/stateManager'
import appUtils from '../../utils/Utils'

interface Props {
  document: Document | undefined
  onDocumentPress: (document: Document) => void
  onDocumentSelected?: (document: Document) => void
}

type ListSection = {
  title: string
  data: Document[]
}

const endOfListThreshold = 0.01

export default function InfoBoxDocumentsView({ document, onDocumentPress, onDocumentSelected }: Props) {
  const { theme } = useTheme()
  const { i18n } = useLanguage()
  const { sessionKey } = useAuthentication()
  const { isSmallDevice } = useLayout()
  const navigation = useNavigation<NavigationProp<InfoBoxStackParamList>>()
  const listRef = useRef<SectionList<Document, ListSection>>(null)

  const [documents, loadDocuments, loading, loadMore, allDocumentsLoaded] = useListLoader<Document, GetDocumentsRequest>(api.getDocuments, {
    take: CONSTANTS.InfoboxDocumentChunkSize,
  })
  const [search, setSearch] = useState('')
  const filter = useRecoilValue(infoboxFilterPreferenceAtom(sessionKey))
  const [lastUsed, setLastUsed] = useRecoilState(lastUsedInfoboxAtom({ sessionKey, infoboxFilter: filter }))

  useEffect(() => {
    validateLastUsed()
  }, [])

  useEffect(() => {
    refresh()
  }, [filter, search])

  function refresh() {
    //try-catch because the scrollToLocation function is not 100% reliable
    try {
      if (Platform.OS !== 'web' && listRef.current && documents) listRef.current.scrollToLocation({ itemIndex: 0, sectionIndex: 0, animated: false })
    } catch (_exception) {
      /* empty */
    }
    loadDocuments({ documentType: filter, searchText: search })
  }

  function validateLastUsed() {
    const promises: Promise<void>[] = []
    let revalidationNeeded = false
    let newLastUsed = cloneDeep(lastUsed)

    lastUsed.forEach(elem => {
      promises.push(
        api
          .getDocuments({ id: elem.id })
          .then(found => {
            if (found.length === 0) {
              revalidationNeeded = true
              newLastUsed = newLastUsed.filter(elemFilter => elemFilter.id !== elem.id)
            }
          })
          .catch(err => console.error(`Failed validation of last used id ${elem.id}`, err))
      )
    })

    revalidationNeeded &&
      Promise.all(promises)
        .then(() => {
          setLastUsed(newLastUsed)
        })
        .catch(err => console.error('Last used validation failed', err))
  }

  function handleDocumentPress(item: Document) {
    updateLastUsedDocument(item)

    if (onDocumentSelected) {
      onDocumentSelected(item)
      return
    }

    if (isSmallDevice) {
      navigation?.navigate('InfoboxFolder', { document: item })
    } else {
      onDocumentPress(item)
    }
  }

  function updateLastUsedDocument(newLastUsedDoc: Document) {
    // Timeout to prevent the user to see the last used update while the modal si appearing on the screen.
    setTimeout(
      () => {
        let clone = cloneDeep(lastUsed)

        const alreadyPresent = clone.find(clonedDoc => clonedDoc.id === newLastUsedDoc.id && clonedDoc.pspDetailId === newLastUsedDoc.pspDetailId)

        if (alreadyPresent) {
          clone = clone.filter(clonedDoc => !(clonedDoc.id === newLastUsedDoc.id && clonedDoc.pspDetailId === newLastUsedDoc.pspDetailId))
        }

        if (lastUsed.length >= CONSTANTS.InfoboxMaxSavedLastUsed) {
          clone.shift()
        }

        clone.push(newLastUsedDoc)
        setLastUsed(clone)
      },
      isSmallDevice ? 500 : 0
    )
  }

  function renderSection(title: string) {
    return (
      <IM.View spacing="all" style={{ backgroundColor: theme.background }}>
        <IM.Text style={{ fontSize: IMStyle.typography.fontSizeSmall }}>{appUtils.translateFilter(title, i18n)}</IM.Text>
      </IM.View>
    )
  }

  const renderNoDocument = () => {
    return (
      <>
        {renderSection(filter)}
        <NoEntry description={i18n.t('NO_DOCUMENT_FOUND')} />
      </>
    )
  }

  const sliceLength = lastUsed.length - CONSTANTS.InfoboxMaxDisplayLastUsed
  const lastUsedElements = useMemo(() => lastUsed.slice(sliceLength > 0 ? sliceLength : -lastUsed.length).reverse(), [lastUsed, sliceLength])

  const data: ListSection[] = useMemo(() => {
    const displayData: ListSection[] = []
    if (!search && lastUsedElements.length) {
      displayData.push({
        title: i18n.t('LAST_USED'),
        data: lastUsedElements,
      })
    }
    if (documents.length && loading !== 'reload') {
      displayData.push({
        title: filter,
        data: documents ?? [],
      })
    }
    return displayData
  }, [lastUsedElements, search, documents, loading])

  return (
    <>
      <DocumentsSearchBox setSearch={setSearch} spacing={['top', 'horizontal']} onDocumentScanned={handleDocumentPress} />
      <SectionList
        ref={listRef}
        sections={data}
        renderSectionHeader={({ section: { title } }) => renderSection(title)}
        renderItem={({ item, section }) => {
          const selected =
            !isSmallDevice &&
            document &&
            document.id === item.id &&
            (document.pspDetailId !== undefined ? document.pspDetailId === item.pspDetailId : true)

          return (
            <IM.View spacing={['bottom', 'horizontal']} key={`DataInfoboxSection${section.title}&${item.id}`}>
              <DocumentCard
                document={item}
                selected={selected}
                onPress={() => {
                  handleDocumentPress(item)
                }}
              />
            </IM.View>
          )
        }}
        ListFooterComponent={
          <>
            {loading === 'reload' && (
              <>
                {renderSection(filter)}
                <IM.View spacing={['bottom', 'horizontal']}>
                  {Array(CONSTANTS.InfoboxSkeletonCard)
                    .fill(0)
                    .map((_val, index) => {
                      return (
                        <IM.View spacing="bottom" key={`SkeletonDataInfobox-${index}`}>
                          <SkeletonCard />
                        </IM.View>
                      )
                    })}
                </IM.View>
              </>
            )}
            {!loading && documents.length === 0 && <>{(isEmpty(documents) || (search !== '' && data.length === 0)) && renderNoDocument()}</>}
            {documents.length > 0 && !allDocumentsLoaded && (
              <IM.View style={styles.listSpacer}>
                <IM.LoadingSpinner isVisible={loading === 'loadMore'} />
              </IM.View>
            )}
          </>
        }
        stickySectionHeadersEnabled
        scrollEnabled={loading !== 'reload'}
        keyboardShouldPersistTaps={Platform.OS === 'android' ? 'handled' : undefined}
        onEndReachedThreshold={endOfListThreshold}
        onEndReached={loadMore}
        refreshControl={Platform.OS !== 'web' ? <IMRefreshControl refreshing={false} onRefresh={refresh} /> : undefined}
        keyExtractor={elm => elm.id + (elm.pspDetailId ?? '')}
      />
    </>
  )
}

const styles = StyleSheet.create({
  listSpacer: {
    height: 50,
  },
})
