import { IM, useLanguage } from '@infominds/react-native-components'
import { NavigationProp, useFocusEffect, useNavigation } from '@react-navigation/native'
import React, { useCallback, useMemo, useState } from 'react'
import { KeyboardAvoidingView, Platform, StyleSheet } from 'react-native'

import Screen from '../../components/Infominds/Screen'
import { ScreenHeader } from '../../components/ScreenHeader'
import TimeHandlerButton from '../../components/TimeHandlerButton'
import { useEmployeeTime } from '../../contexts/EmployeeTimeContext'
import useLayout from '../../hooks/useLayout'
import useNewTimeHandler from '../../hooks/useNewTimeHandler'
import useUserSettings from '../../hooks/useUserSettings'
import { NewTimeModalData } from '../../modals/NewTimeModal'
import { HistoryStackScreenProps, HomeStackParamList, HomeStackScreenProps } from '../../navigation/type'
import { ModalScreenProps } from '../../types'
import DateUtils from '../../utils/DateUtils'
import CurrentTimeView from '../../views/NewTime/CurrentTimeView'
import TimeSelectionView from '../../views/NewTime/TimeSelectionView'

export default function NewTimeScreen({
  route,
  controller,
}: Omit<HomeStackScreenProps<'NewTime'> | HistoryStackScreenProps<'HistoryNewTime'>, 'navigation'> & ModalScreenProps<NewTimeModalData>) {
  const { language } = useLanguage()
  const { isSmallDevice } = useLayout()
  const { userSettings } = useUserSettings()
  const navigation = useNavigation<NavigationProp<HomeStackParamList>>()

  const { currentTime, lastTime, isTimeActive, todaysTimes, getCurrentTime, updateCurrentTime } = useEmployeeTime()
  const handler = useNewTimeHandler({
    presetActivity: route.params?.activity,
    presetDocument: route.params?.document,
    activeTime: currentTime,
    presetEmployees: lastTime?.subordinates,
  })

  const [showLastTimeId, setShowLastTimeId] = useState(currentTime?.id)

  useFocusEffect(
    useCallback(() => {
      getCurrentTime().catch(console.error)
    }, [])
  )

  const showTime = useMemo(() => {
    if (isTimeActive) return currentTime

    if (!showLastTimeId) return undefined

    return todaysTimes.find(time => time.id === showLastTimeId)
  }, [isTimeActive, currentTime, todaysTimes, showLastTimeId])

  const handleNoteChanged = useCallback(
    (note: string) => {
      if (!currentTime || note === currentTime?.note || (!note && !currentTime?.note)) {
        return new Promise<void>(() => {
          return
        })
      }

      return updateCurrentTime(handler.note)
    },
    [currentTime, handler.note]
  )

  return (
    <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} style={styles.keyboardAvoidingView}>
      <Screen
        hideBackgroundImage={!isSmallDevice}
        screenHeader={
          <ScreenHeader
            goBack={() => {
              isSmallDevice ? navigation.goBack() : controller?.close()
            }}
            title={DateUtils.formatDate(new Date(), 'dd MMMM yyyy', language)}
          />
        }>
        <IM.ScrollView>
          <TimeSelectionView
            handler={handler}
            activeTime={currentTime}
            onNoteChanged={handleNoteChanged}
            creationMode={!userSettings?.isEmployeeTimeOnlineRecordingActive}
            isCreationAlwaysAllowed
            onModalClose={() => {
              controller ? controller.close() : undefined
            }}
          />

          {!!showTime && <CurrentTimeView time={showTime} />}
          <TimeHandlerButton
            handler={handler}
            spacing="all"
            postStart={setShowLastTimeId}
            postStop={() => navigation.goBack()}
            creationMode={!userSettings?.isEmployeeTimeOnlineRecordingActive}
            postCreated={() => navigation.goBack()}
          />
        </IM.ScrollView>
      </Screen>
    </KeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  keyboardAvoidingView: {
    height: '100%',
  },
})
